.topHeaderRight.dropdowb__btn {
    background: rgb(255 255 255/20%);
    border-radius: 3px;
    padding: 25px 15px;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.topHeaderRight.dropdowb__btn .selectedService {
    color: #fff;
}
.topHeaderRight.dropdowb__btn .changeService {
    color: #ffffff99;
    cursor: pointer;
}
.topHeaderRight.dropdowb__btn .changeService:hover {
    color: #ffffff;
}
.topHeaderRight.dropdowb__btn span svg {
    margin-left: 5px;
    margin-right: 5px;
}

.topHeaderRight.dropdowb__btn:hover {
    background: rgb(255 255 255/20%);
}
.topHeaderRight.dropdowb__btn:hover .dropdowb__btn__icon {
    color: #fff;
}
.topHeaderRight.dropdowb__btn span {
    margin-left: 5px;
    margin-right: 5px;
}

.modalContainer {
    display: flex;
    padding: 2rem 4rem 4rem;
    flex-direction: column;
}

.headerText {
    margin-top: 1rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 3rem;
}

.modalContainer {
    display: flex;
    padding: 2rem 4rem 4rem;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    margin-right: 0 !important;
    color: #f56666 !important;
    background-color: #feeded !important;
    border-radius: 0 !important;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #f56666 !important;
}

.selectedServiceGroup {
    display: flex;
    align-items: center;
}

.headerText {
    margin-top: 1rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 3rem;
}



.serviceButtonContainer {
    border: none;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 2rem 2rem;
    color: #8bc334;
    cursor: pointer;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: left;
    background-color: #f1f8e7;
    width: 100%;
}

.serviceSelectedButtonContainer {
    color: white;
    font-size: 20px !important;
    line-height: 24px !important;
    background-color: #8bc334;
    width: 100%;
}

.serviceButtonContainer:hover {
    color: #ffffff;
    background-color: #8bc334;
}

.displayAddServiceButtonGroup {
    display: flex;
    align-items: center;
    /* display: none; */
}

.displayAddServiceButtonGroup div {
    /* flex: 0.5; */
    flex: 1;
}

.displayAllServiceButton {
    margin-right: 20px;
    display: none;
}

.serviceCheck {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

.serviceNotCheck {
    width: 50px;
    height: 50px;
    background: rgba(139, 195, 52, 0.15);
}

.serviceButtonContainer:hover .serviceNotCheck {
    background: rgba(255, 255, 255, 0.2);
}

.lapsedButton {
    border-radius: 5px;
    padding: 2rem 2rem;
    color: white;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: left;
    font-weight: 500 !important;
    margin-bottom: 1rem;
    background: rgba(245, 102, 102, 0.12);
    color: #f56666;
}

.lapsedIcon {
    width: 50px;
    height: 50px;
    background: rgba(245, 102, 102, 0.15);
    border-radius: 3px;
}

.subscribeButton {
    padding: 2rem 2rem;
    border-radius: 5px;
    background: rgba(245, 102, 102, 0.15);
}

.subscribeButton:hover {
    background: rgba(245, 102, 102, 0.18);
}
