.textPrompt {
  font-size: 25px;
  max-width: 500px;
  text-align: center;
  line-height: 30px;
}

.actionBtns button {
  color: #fff;
  padding: 15px 35px;
  font-size: 19px;
  border-radius: 3px;
}

.actionBtns button:not(:last-child) {
  margin-right: 10px;
}

.confirmBtn {
  background: #388cef;
}

.declineBtn {
  background: #f56666;
}

.error{
    color: #f56666;
    font-size: 19px;
    margin-top: 20px;
}