@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: hidden;
}

:root {
  --horspace: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.mainContent {
  margin: 30px 0 30px 0;
}

.container {
  width: 1440px;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
  box-sizing: border-box;
}

/*---Header---*/

.header {
  background-color: #222222;
}

.header .headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
}

.loggedIn .headerRow {
  padding: 0 10px;
}

.loggedOut .headerRow {
  padding: 10px;
  height: 71px;
}

.header .headerRow .logo a {
  display: flex;
  margin-right: 30px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header .headerRow .logo img {
  width: 100%;
  max-width: 175px;
}

.header .headerRow .headerMenu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.loggedIn .headerRow .headerMenu {
  justify-content: space-between;
}

.loggedOut .headerRow .headerMenu {
  justify-content: flex-end;
}

.loggedOut .headerButtons .SignOut,
.loggedOut .menuContainer {
  display: none !important;
}

.loggedOut .headerButtons {
  display: flex;
}

.headerButtons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.headerButtons > * {
  margin-right: 10px;
}

.loggedIn .headerButtons .SignIn {
  display: none !important;
}

.loggedOut .headerButtons a:first-child {
  margin-right: 10px;
}

.loggedOut.removeHeaderBtn .headerButtons {
  display: none;
}

.loggedOut .footerMenu,
.loggedOut .footerTop {
  display: none;
}

.loggedOut .footer .footerBottom .footerRow {
  justify-content: flex-end;
}

.header .headerRow .headerButtons a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #f9f9f9;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header .headerRow .headerButtons a:hover {
  opacity: 0.8;
}

.header .headerRow .headerButtons a > * {
  margin-right: 8px;
}

.header .headerRow .headerButtons .btnGreen {
  border: 1px solid #8ac04a;
  background-color: #8ac04a;
}

.header .headerRow .headerButtons .btnBlue {
  border: 1px solid #388cef;
  background-color: #388cef;
}

.header .menuContainer .menus {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header .menuContainer .menus .menuItem {
  position: relative;
}

.header .menuContainer .menus .menuItem:hover > .subMenuItem {
  display: block;
}

.header .menuContainer .menus .menuItem .subMenuItem {
  position: absolute;
  top: 100%;
  background-color: #222222;
  z-index: 9;
  /* width: 180px; */
  width: auto;
  display: none;
}

.header .menuContainer .menus .menuItem .subMenuItem li:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.header .menuContainer .menus .menuItem .subMenuItem li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.header .menuContainer .menus .menuItem .subMenuItem li a > * {
  margin-left: 4px;
}

.header .menuContainer .menus .menuItem .subMenuItem li .trialBtn {
  font-size: 12px;
  line-height: 28px;
  color: #8ac04a;
  background-color: rgb(138 192 74 / 40%);
  border: 1px solid rgb(138 192 74 / 40%);
  border-radius: 2px;
  /* width: 45px; */
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
}

.header .menuContainer .menus .menuItem .subMenuItem li .signupBtn {
  font-size: 12px;
  line-height: 28px;
  color: #f56666;
  background-color: rgb(245 102 102 / 40%);
  border: 1px solid rgb(245 102 102 / 40%);
  border-radius: 2px;
  /* width: 45px; */
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
}

.header .menuContainer .menus .menuItem .messageCount {
  font-size: 12px;
  color: #ffffff;
  background: #388cef;
  border-radius: 2px;
  width: 22px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: -8px;
}

.header .menuContainer .menus .menuItem .messageCount::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #388cef;
  position: absolute;
  bottom: -6px;
  right: 0;
  left: -5px;
  margin: auto;
}

.header .menuContainer .menus .menuItem > a {
  padding: 25px 15px;
}

.header .menuContainer .menus .menuItem a {
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
}

.header .menuContainer .menus .menuItem.active a,
.header .menuContainer .menus .menuItem a:hover {
  background-color: rgb(255 255 255 / 7%);
}

/*---Footer---*/

.footer .footerRow {
  max-width: 1440px;
  margin: auto;
}

.footer h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}

.footer .footerTop {
  background-color: #222222;
}

.footer .footerTop .footerRow {
  box-sizing: border-box;
  padding: 35px 50px 45px 50px;
}

.products {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.products .product {
  background: rgb(255 255 255 / 10%);
  border-radius: 3px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
}

.products .product svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255 255 255 / 40%);
  font-size: 14px;
}

.products .product span {
  font-size: 16px;
  line-height: 28px;
  color: rgb(255 255 255 / 40%);
  display: block;
  margin-bottom: 5px;
}

.products .product:not(.commingSoon):hover svg,
.products .product:not(.commingSoon):hover span,
.products .product.subscribed.active svg,
.products .product.subscribed.active span {
  color: #ffffff;
}

.products .product a {
  font-size: 12px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase;
}

.products .product.commingSoon a {
  background-color: #ff8888;
  cursor: not-allowed;
}

.products .product:not(.commingSoon):hover a,
.products .product.subscribed.active a {
  color: #8ac04a;
  background-color: #ffffff;
}

.products .product.visit a {
  background-color: #8ac04a;
}

.products .product.visit a.access-sign-up {
    background-color: #ff8888;
}

.products .product.subscribed a {
  background-color: #8ac04a;
}

.products .product:not(.commingSoon):hover,
.products .product.subscribed.active {
  background-color: #8ac04a;
}

.products .product.active svg,
.products .product.active span {
  color: #ffffff;
}

.products .product.active a {
  color: #8ac04a;
  background-color: #ffffff;
}

.footer .footerBottom {
  background-color: #222222;
}

.footer .footerBottom .footerRow {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 25px;
  box-sizing: border-box;
}

.footer .footerBottom .footerRow .footerMenu {
  margin-bottom: 15px;
  width: 100%;
}

.footer .footerBottom .footerRow .footerCopyright {
  width: 100%;
}

.footer .footerBottom .footerRow .footerCopyright span {
  display: block;
  text-align: center;
}

.footer .footerBottom .footerRow .footerMenu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footerBottom .footerMenu ul li:not(:last-child) {
  margin-right: 15px;
}

.footerBottom .footerCopyright span,
.footerBottom .footerMenu li .footerMenuText {
  font-size: 16px;
  line-height: 32px;
  color: rgb(255 255 255 / 60%);
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: unset;
  overflow: auto;
  background-color: rgb(235 235 235 / 0.9);
  display: none;
  z-index: 9999999;
}

.modalContainer {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  margin: auto;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}

.closeModal {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  background-color: #f56666;
  border: unset;
  outline: none;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.closeModal:hover {
  color: #f56666;
  background-color: rgb(245 102 102 / 12%);
}

.subscriptionPaymentWarning {
  background-color: #ffffff;
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: center;
}

.subscriptionPaymentWarning p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #272828;
  margin: 0 0 0 10px;
}

.subscriptionPaymentWarning p span,
.subscriptionPaymentWarning svg {
  color: #f56666;
}

.pageBanner {
  position: relative;
  z-index: 1;
}

.pageBanner::after {
  content: "";
  background: rgba(46, 46, 46, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

@media only screen and (max-width: 1100px) {
  .loggedIn .headerMenu .headerButtons,
  .loggedIn .headerMenu .menuContainer {
    display: none;
  }

  .loggedIn .headerRow .headerMenu {
    justify-content: flex-end;
  }

  .loggedIn .mobileMenu {
    display: block !important;
  }

  .mobileMenu .menuBars {
    width: 40px;
    height: 60px;
    background: unset;
    border: unset;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .mobileMenu .bars {
    position: relative;
    width: 40px;
    height: 22px;
    border-top: 3px solid #f9f9f9;
    border-bottom: 3px solid #f9f9f9;
    transition: all 0.2s ease;
    margin-right: 0 !important;
  }

  .mobileMenu .menuBars .bars:after,
  .mobileMenu .menuBars .bars:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #f9f9f9;
    transition: all 0.2s ease;
  }

  .mobileMenu .menuBars.on .bars {
    border-color: transparent;
  }

  .mobileMenu .menuBars.on .bars:before {
    transform: rotate(45deg);
  }

  .mobileMenu .menuBars.on .bars:after {
    transform: rotate(-45deg);
  }

  .mobileMenu .menus.slideDown {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
  .mobileMenu .menus {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    background-color: #222222;
    z-index: 9;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1),
      opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
  }

  .mobileMenu .menus li {
    border-top: 1px solid rgb(255 255 255 / 20%);
    position: relative;
  }

  .mobileMenu .menus .messageCount {
    font-size: 12px;
    color: #ffffff;
    background: #388cef;
    border-radius: 2px;
    width: 22px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 100px;
  }

  .mobileMenu .menus .messageCount::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #388cef;
    position: absolute;
    bottom: -6px;
    right: 0;
    left: -5px;
    margin: auto;
  }

  .mobileMenu .menus a {
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
    display: inline-flex;
    padding: 10px 15px;
    align-items: center;
    margin: 15px 0;
  }

  .mobileMenu .menus a .trialBtn {
    font-size: 12px;
    color: #8ac04a;
    background-color: rgb(138 192 74 / 40%);
    border: 1px solid rgb(138 192 74 / 40%);
    border-radius: 2px;
    width: 45px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-clip: padding-box;
    margin-left: 10px;
  }

  .mobileMenu .menus li:hover > a {
    background-color: rgb(255 255 255 / 7%);
  }
}

@media only screen and (min-width: 500px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  /* :root { */
  /* --horspace: 50px; */
  /* } */
}

@media screen and (min-width: 1100px) {
  .header .headerRow .logo {
    width: 15%;
  }
  .header .headerRow .headerMenu {
    width: 85%;
  }

  .footer .footerBottom .footerRow {
    align-items: center;
    justify-content: space-between;
  }

  .footer .footerBottom .footerRow .footerMenu {
    margin-bottom: 0;
    width: unset;
  }

  .footer .footerBottom .footerRow .footerCopyright {
    width: unset;
  }

  .footer .footerBottom .footerRow .footerCopyright span {
    text-align: unset;
  }
}

@media screen and (min-width: 1225px) {
  .products {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1600px) {
  :root {
    --horspace: 100px;
  }
}

@media only screen and (min-width: 1800px) {
  :root {
    --horspace: 150px;
  }
}

.header-top-area {
    z-index: 99999;
    width: 200px;
    height: 51px;
}